import {useState} from 'react'

export const useDisconnect = (socket) => {
    const [isOnline, setIsOnline] = useState(true)

    socket.on ('connect', () => {
        setIsOnline(true)
    })

    socket.on ('disconnect', () => {
        setIsOnline(false)
    })

    return {isOnline, setIsOnline}
}

