import React, {Suspense, lazy} from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'

//mport {Replayer} from './pages/Replayer' 

//const RatingPage = lazy(() => import('./pages/RatingPage'))  
const ArchivePage = lazy(() => import('./pages/ArchivePage'))
const Homepage = lazy(() => import('./pages/HomePage'))
const LoginPage = lazy(() => import('./pages/LoginPage'))
const PasswordRecoveryPage = lazy(() => import('./pages/PasswordRecoveryPage'))
const EmailVerificationPage = lazy(() => import('./pages/EmailVerificationPage'))
const LobbyPage = lazy(() => import('./pages/LobbyPage/LobbyPage'))
const Game = lazy(() => import('./pages/Game'))
const Invitation = lazy(() => import('./pages/Invitation'))
const CreateOTB = lazy(() => import('./pages/TournamentOTB/CreateOTB'))
const OTBTournament = lazy(() => import('./pages/TournamentOTB/OTBTournament'))
// const GameOTB = lazy(() => import('./pages/TournamentOTB/GameOTB'))
// const TestPage = lazy(() => import('./pages/TestPage'))

export const useRoutes = (isAuthenticated, isAdmin) => {
    // const {isAuthenticated} = useContext(AuthContext)
    
    if (!isAuthenticated) { // not auth
    return (   
        <Suspense fallback={<div>Loading...</div>}> 
            <Routes>
                <Route path="/" element={<Homepage/>} />
                <Route path="/invite/:id" element={<Invitation/>} />
                <Route path="/login" element={<LoginPage/>} />
                <Route path="/recover/:token" element={<PasswordRecoveryPage/>} />
                <Route path="/verification/:token" element={<EmailVerificationPage/>} />
                {/* <Route path="/rating_list" element={<RatingPage isAuthenticated = {isAuthenticated} userId = {userId} socket = {socket}/>} />*/}
                <Route path="/archive/:id" element={<ArchivePage/>} />  
                <Route path="/lobby" element={<LobbyPage/>} /> 
                <Route path="/live" element={<CreateOTB/>} />
                <Route path="/live/:id" element={<OTBTournament/>}/>
                {/* <Route path="/live/:id/:gameId" element={<GameOTB/>}/> */}
                {/* <Route path="/live/:id/:playerId" element={<RoundsByPlayer/>}/> */}
                <Route path="/game/:id" element={<Game/>} /> 
                {/* <Route path="/test" element = {<TestPage/>} /> */}
                <Route path="*" element={<Navigate to="/" />} />            
            </Routes>
        </Suspense>
        )
    }
    return ( // auth
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>            
                <Route path="/" element={<Homepage/>} />
                <Route path="/invite/:id" element={<Invitation/>} />
                {/* <Route path="/rating_list" element={<RatingPage isAuthenticated = {isAuthenticated} userId = {userId} socket = {socket}/>} /> */}
                <Route path="/lobby" element={<LobbyPage/>} />
                <Route path="/verification/:token" element={<EmailVerificationPage/>} />
                {/* {isAdmin ? <Route path="/live" element={<CreateOTB/>} /> : <></>} */}
                <Route path="/live" element={<CreateOTB/>} />
                <Route path="/live/:id" element={<OTBTournament/>}/>
                {/* <Route path="/live/:id/:gameId" element={<GameOTB/>}/> */}
                <Route path="/archive/:id" element={<ArchivePage/>} /> 
                
                {/* <Route path="/test" element = {<TestPage/>} /> */}
                <Route path="/login" element={<LoginPage/>} />

                <Route path="/game/:id" element={<Game/>} />
                <Route path="*" element={<Navigate to="/" />} />   
            </Routes>
        </Suspense>
    )
}

