import React, { useEffect } from 'react'
import { BrowserRouter as Router} from 'react-router-dom'
import { useRoutes } from './routes'
import { ModalDisconnect } from './pages/elements/ModalDisconnect'
import { useAuth } from './hooks/auth.hook'
import { AuthContext } from './context/AuthContext'
import { UserContext } from './context/UserContext'
import { SFXContext } from './context/SFXContext'
import { useSettings } from "./hooks/settings.hook"
import { usePlaying } from "./hooks/isPlaying.hook"
import { useDisconnect } from "./hooks/disconnection.hook"
import { useAudio } from "./hooks/audio.hook"
import { useChat } from "./hooks/chat.hook"
import { useBell } from "./hooks/notifications.hook"
import io from "socket.io-client"
import 'react-toastify/dist/ReactToastify.css';

const storageName = 'userData'

// const socket = io('http://192.168.1.100:4000', {
//   auth: async (cb) => {
//     const data = await JSON.parse(localStorage.getItem(storageName))
//     cb({
//       token: data?.token,
//       sid: data?.sid
//     })
//   },
//   forceNew: false
// }) 

const socket = io({
  auth: async (cb) => {
    const data = await JSON.parse(localStorage.getItem(storageName))
    cb({
      token: data?.token,
      sid: data?.sid
    })
  }
})

function App() {
  // add offline mode with Play bot availiable, game history of last 30 games?
  const {token, login, logout, userId, nick, isAuthenticated, isAdmin} = useAuth(socket)
  const {settings, setSettings} = useSettings()
  const {chatOpened, setChatOpened, typing, setTyping} = useChat()
  const {isPlaying, setIsPlaying} = usePlaying()
  const {notificationsOpen, setNotificationsOpen} = useBell()
  const {isOnline} = useDisconnect(socket)
  const {playBullet, playMove, playTick} = useAudio()

  useEffect(()=> {
    socket.on('connect', ()=> {
      socket.emit('startHeartbeat')
    })

    socket.on('heartbeat', () => {})
  },[socket])


  return (
    <AuthContext.Provider value = {{token, userId, login, logout, isAuthenticated, socket, isAdmin}}>
      <Router>
        <UserContext.Provider value = {{nick, isOnline, isPlaying, setIsPlaying, settings, setSettings, chatOpened, setChatOpened, typing, setTyping, setNotificationsOpen, notificationsOpen}}> 
          <SFXContext.Provider value = {{playBullet, playMove, playTick}}>
            {useRoutes(isAuthenticated, isAdmin)}
            {!isOnline ? <ModalDisconnect/> : <></>}
          </SFXContext.Provider>
        </UserContext.Provider> 
      </Router>
    </AuthContext.Provider>
  )
}

export default App;
